<style lang="scss" scoped>
/deep/ .comp-company-edit {
    margin-top: 6vh !important;
    max-width: 800px;
}
</style>

<template>
    <div>
        <el-dialog title="事业部编辑" custom-class="c-el-dialog comp-company-edit" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="130px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="事业部名称" prop="name">
                            <el-input v-model="dialogData.name" placeholder="请输入事业部名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funInfomation from "@/api/information"
import configMixins from "@/mixins/config"
export default {
    name: "compCompanyEdit",
    mixins: [configMixins],
    data() {
        return {
            showDialog: false,
            dialogData: {},
            defaultDialogData: {
                name: ""
            },
            dialogType: "",
            formRefName: "refCompanyEdit",
            formRules: {
                name: [{ required: true, message: '请输入事业部名称', trigger: 'blur'}]
            },
            defaultProps: {
                value: "id",
                children: "children",
                label: "name",
                expandTrigger: "hover",
                checkStrictly: true,
                emitPath: false
            }
        }
    },
    methods: {
        open(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            if (row) {
                this.dialogType = this.createOrUpdate[1]
                // 编辑
                funInfomation.getCompanyDetail({id: row.id}).then(res => {
                    this.dialogData = res
                    this.showDialog = true
                })
            } else {
                this.dialogType = this.createOrUpdate[0]
                this.showDialog = true
            }
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    window.$common.fullLoading()
                    if (this.dialogType == this.createOrUpdate[0]) {
                        // 创建
                        funInfomation.setCompany(commitData).then((res) => {
                            this.commonSuccess("操作成功!", res)
                        })
                    } else {
                        // 修改
                        funInfomation.updateCompany(commitData).then((res) => {
                            this.commonSuccess("操作成功!", res)
                        })
                    }
                } else {
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData(data)
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>