<style lang="scss" scoped>
	.comp-htmlscan {
		margin: 0;
		width: 100%;
		height: auto;
        min-height: calc(100% - 50px);
        margin-bottom: 50px;
		background: #ffffff;
		.reader {
			margin: 0 auto 0;
			width: 100%;
			min-width: 300px;
			height: auto;
			min-height: 200px;
			background: url('/static/images/icons/image-failed.png') 50% 48% no-repeat;
		}
		.buttons {
			margin-top: 20px;
            text-align: center;
			.tips {
				padding: 10px;
				font-size: 13px;
				text-align: center;
                background: #fdfdfd;
                display: inline-block;
                min-width: 280px;
                margin: 0 auto;
                border-radius: 6px;
                border: 1px solid #eee;
                font-weight: bold;
			}
			.button {
				text-align: center;
				margin-top: 10px;
				.el-button {
					min-width: 300px !important;
				}
			}
			.scan {
				.el-button {
					background: #f77d7b;
				}
			}
		}
	}
</style>
<template>
    <el-drawer :with-header="false"  custom-class="distribution-drawer" size="100%" :visible.sync="showScan" direction="ttb" 
        :append-to-body="true">
        <div class="comp-htmlscan" v-show="showScan">
            <div class="reader" :id="readerId"></div>
            <div class="buttons">
                <div class="tips col_primary">{{ errorText }}</div>
                <div class="button">
                    <!--<el-button type="primary" size="medium" @click="getScanFile">拍照或从相册识别</el-button>-->
                    <el-upload class="" :show-file-list="false"
                        accept="image/jpeg,image/gif,image/png"
                        action=""
                        :before-upload="beforeAvatarUpload">
                        <el-button size="medium" type="primary">拍照或从相册识别</el-button>
                    </el-upload>
                </div>
                <div class="button scan">
                    <el-button type="danger" size="medium" @click="getCameras">重新扫描</el-button>
                </div>
                <div class="button">
                    <el-button type="default" size="medium" @click="_close">关闭</el-button>
                </div>
            </div>
        </div>
    </el-drawer>
</template>
<script>
	import { Html5Qrcode  } from "html5-qrcode"
	export default {
        props: {
            reader: {
                type: String,
                default: "reader"
            }
        },
		data() {
			return {
                readerId: "",
				showScan: false,
				html5QrCode: null,
				cameraId: null,
				errorText: "",
                operaRow: null,
                lastSuccessTime: null
			}
		},
		created() {
			// this.getCameras()
		},
		beforeDestroy() {
			this._destroy()
		},
		methods: {
			open(row) {
                this.readerId = this.reader
                this.operaRow = row || null
				this.showScan = true
				this.errorText = "无法扫描或识别不成功？请点击下方按钮!"
				setTimeout(() => {
                    this.getCameras()
                }, 500)
			},
			getCameras() {
                this._reset()
				Html5Qrcode.getCameras().then(devices => {
					if (devices && devices.length) {
						if (devices.length === 1) {
							this.cameraId = devices[0].id // 前置摄像头
						} else {
							this.cameraId = devices[1].id // 后置摄像头
						}
						if (this.cameraId) {
							this.startWithCameraId()
						}
					}
				}).catch(err => {
					// 没有相机，选择图片或者拍照
                    this.html5QrCode = new Html5Qrcode(this.readerId)
                    this.warningMsg("无法找到相机，请手动拍照或从相册识别!")
				})
			},
            // 本地选则扫码图片
            beforeAvatarUpload(file) {
                window.$common.closeFullLoading()
                this.getScanFile(file)
                return false
            },
			getScanFile(imageFile) {
				this.stop()
				this.html5QrCode.scanFile(imageFile, true).then(decodedText => {
                    this.onSuccess(decodedText)
                }).catch(err => {
                    this.errorText = "识别不成功!请重新点击下方按钮识别!"
                    window.$common.closeFullLoading()
                })
			},
			// 有相机ID的扫码
			startWithCameraId() {
                // 配置项备注：{ facingMode: "environment" } || { facingMode: { exact: "environment" }
                this.html5QrCode = new Html5Qrcode(this.readerId)
				this.html5QrCode.start({ deviceId: { exact: this.cameraId } }, {
					fps: 10,
					qrbox: 250
				}, this.onSuccess, this.onFailure).catch(err => {
					console.error("相机初始化错误，请手动扫码或输入!")
                    this.warningMsg("相机初始化错误，请手动扫码或输入!")
				})
			},
			onSuccess(decodedText, decodedResult) {
				window.$common.closeFullLoading()
                let _nowTime = +new Date()
                this.errorText = decodedText
                // 间隔1.5s再添加
                if (_nowTime - this.lastSuccessTime > 1500 || !this.lastSuccessTime) {
                    this.$emit("success", decodedText, this.operaRow)
                    this.lastSuccessTime = _nowTime
                }
                if (!this.operaRow) {
                    this._close()
                }
			},
			onFailure(error) {
				console.error("识别失败!")
			},
			stop() {
                this.lastSuccessTime = null
				try{
					this.html5QrCode && this.html5QrCode.stop && this.html5QrCode.stop().then(ignore => {
						console.log("QR Code scanning stopped.")
					}).catch(err => {
						console.log("Unable to stop scanning.")
					})
				} catch(e) {
					console.log("Stop failed, 无法找到相机!")
				}
			},
            _reset() {
                this.stop()
                this.html5QrCode = null
                this.cameraId = null
                this.errorText = "无法扫描或识别不成功？请点击下方按钮!"
            },
            _destroy() {
                this.stop()
				this.showScan = false
				this.html5QrCode = null
				this.cameraId = null
				this.errorText = ""
                this.operaRow = null
            },
			_close() {
				this._destroy()
                this.$emit("close", true)
			}
		}
	}
</script>
