export default {
  data () {
      return {
          createOrUpdate: ["create", "update"], // 当前是新增或者更新，弹窗分类
      }
  },
  methods: {
    // 解析二维码中的字符串
    parsingQRCode (str) {
      if (!str) {
        return false
      }
      // 格式：B249020BC4?{"bar_code":"1111111111","model":"2222222222"}
      let temp = {
        serial: ""
      }
      let markIndex = str.indexOf("?")
      if (markIndex === -1) {
        temp.serial = str
      } else {
        // 解析参数
        temp.serial = str.substring(0, markIndex)
        let params = str.substring(markIndex + 1)
        try {
          params = JSON.parse(params)
          temp = {
            ...temp,
            ...params
          }
        } catch (e) {
          // 参数解析失败
          console.log(e)
        }
      }
      console.log(temp)
      return temp
    }
  }
}


