<style scoped lang="scss">
/deep/ .comp-scanserial-el-dialog {
  margin-top: 6vh !important;
  max-width: 520px;
  .scan-serials {
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 6px;
    line-height: 1.8;
    .scan-nodata {
      color: #999;
    }
  }
}
/deep/ .comp-scanserial-pro-el-dialog {
  max-width: 800px;
  .asset-image {
      width: 26px;
      height: 26px;
      vertical-align: middle;
      .error-image {
          font-size: 26px;
          color: #cacaca;
      }
  }
}
.v-modal {
  opacity: 0.1;
}
</style>

<template>
  <div>
    <el-dialog title="扫码自动识别" custom-class="c-el-dialog comp-scanserial-el-dialog" :visible.sync="showDialog" 
      :before-close="_close" :close-on-click-modal="false" append-to-body>
      <div class="mrgt10 mrgb10">
        <el-input size="medium" placeholder="扫码枪扫描后，将会自动添加!" clearable
          :ref="refScanSerialFocus" v-model="curSerial" @keyup.enter.native="_add()" @blur="autoFocus()">
          <template slot="append" v-if="!$root.isPc"><span @click="openSerialHtmlScan()"><i class="el-icon-full-screen"></i></span></template>
        </el-input>
      </div>
      <div class="scan-serials">
        <div class="scan-nodata">
          <div>1、请使用扫码枪扫描资产序列号，可以连续扫码，点击结束扫码则退出；</div>
          <div>2、扫码完成后将自动查询出资产信息，并添加到资产列表中；</div>
          <div>3、序列号会去重，即同一序列号只会成功添加一次；</div>
        </div>
      </div>
      <span slot="footer">
          <el-button size="small" type="primary" @click="_close()">结束扫码</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择出库商品" custom-class="c-el-dialog comp-scanserial-pro-el-dialog" :visible.sync="showDialogPro" :before-close="_closePro" :close-on-click-modal="false">
      <el-table :data="tableData">
        <el-table-column label="图片【分类-条形码】资产名称">
            <template slot-scope="scope">
                <el-image class="asset-image" v-if="scope.row.asset.imageUrl"
                    :src="uploadBaseUrl + scope.row.asset.imageUrl" 
                    :preview-src-list="[uploadBaseUrl + scope.row.asset.imageUrl]">
                    <div slot="error">
                        <i class="el-icon-picture-outline error-image"></i>
                    </div>
                </el-image>
                <span>{{ mxTranceAssetData(scope.row.asset) }}</span>
                <span :class="mxTranceAssetData(scope.row.asset) ? '' : 'mrgl10'">{{ scope.row.asset.name }}</span>
            </template>
        </el-table-column>
        <el-table-column label="序列号" width="180">
          <template slot-scope="scope">
              {{ scope.row.sn }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="selectPro(scope.row, scope.$index)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!--调用摄像头扫码-->
    <html-scan :ref="refHtmlScan" :reader="'scanSerialReader'" @success="htmlScanSuccess(arguments)" @close="htmlScanClose()"></html-scan>
  </div>
</template>

<script>
import configMixins from "@/mixins/config"
import { getStockSnSearch } from "@/api/stock"
import HtmlScan from "@/components/HtmlScan"
import { Weixin } from '@/utils/wx-jssdk.js'
import * as funCommon from "@/api/common"
export default {
  name: "scanSerial",
  mixins: [configMixins],
  components: { HtmlScan },
  data() {
    return {
      showDialog: false,
      refScanSerialFocus: "refScanSerialAutoFocus",
      refHtmlScan: "refScanSerialToHtmlScan",
      curSerial: "",
      submitScanDatas: [], // 需要提交的商品数据
      tempSerialDatas: [],
      tempOutStockDatas: [],
      listQuery: {
        assetId: "",
        ownerType: null,
        ownerId: "",
        keywords: "",
        maxResultCount: 20
      },
      // 选择出库商品
      showDialogPro: false,
      tableData: []
    }
  },
  methods: {
    open(params) {
      this.curSerial =""
      this.listQuery.ownerId = params && params.ownerId || ""
      this.showDialog = true
      this.autoFocus()
    },
    autoFocus() {
      this.$nextTick(() => {
        this.curSerial =""
        this.$refs[this.refScanSerialFocus].focus()
      })
    },
    async getWechatSign() {
        await funCommon.GetWechatTicket().then(res => {
            let jssdkRes = res
            let wxJS = new Weixin()
            wxJS.init({
                appId: jssdkRes.appId, // 必填，公众号的唯一标识
                timestamp: jssdkRes.timestamp, // 必填，生成签名的时间戳
                nonceStr: jssdkRes.nonceStr, // 必填，生成签名的随机串
                signature: jssdkRes.signature // 必填，签名
            })
            wxJS.scanQRCode(wres => {
                this.htmlScanSuccess([wres.resultStr])
            })
        })
    }, 
    // 打开序列号扫码
    openSerialHtmlScan() {
        let browser = window.$util.getBrowser()
        if (browser && browser.wechat) {
            this.getWechatSign()
        } else {
            this.$refs[this.refHtmlScan].open(true)
        }
    },
    // 调用摄像头扫码成功
    htmlScanSuccess(arr) {
      let qrCodeItem = this.parsingQRCode(arr[0])
      this.curSerial = qrCodeItem.serial
      this._add()
    },
    htmlScanClose() {
      if (!this.showDialogPro) {
        this._close()
      }
    },
    _add() {
      if (!this.curSerial) {
        this.warningMsg("请扫描商品序列号!")
        return false
      }
      this.listQuery.keywords = this.curSerial
      window.$common.fullLoading()
      getStockSnSearch(this.listQuery).then(res => {
        window.$common.closeFullLoading()
        this.tableData = res || []
        if (!res || res.length <= 0) {
          this.errorMsg("序列号不存在!")
        } else {
          // 有拥有者，则需要判断库存
          if (this.listQuery.ownerId && res[0].asset.stock <= 0) {
            this.warningMsg(`${res[0].asset.name}，库存不足!`)
            return false
          }
          if (res.length > 1) {
            // 多个商品，则弹出手动选择商品界面
            this.showDialogPro = true
          } else {
            // 只有一条商品数据，则自动识别
            let item = res[0]
            this.commonAdd(item)
          }
        }
      })
      // 清空扫码框的值
      this.curSerial =""
    },
    selectPro(row, index) {
      this.commonAdd(row)
      this._closePro()
    },
    commonAdd(item) {
      let curItem = {}
      curItem.imageUrl = item.asset.imageUrl
      curItem.assetId = item.asset.id
      curItem.assetName = item.asset.name
      curItem.barCode = item.asset.barCode
      curItem.needSerialNumber = item.asset.hasSerialNumber || false
      curItem.serialNumber = item.sn
      curItem.maxCount = this.listQuery.ownerId ? item.asset.stock : -1 // 设置为-1，表示不需要控制最大数量
      curItem.selectNumbers = []
      this.tempOutStockDatas = [curItem]
      this._submit()
    },
    _submit() {
      // 执行方式跟选择批次一样，数据格式也一样
      this.$emit("scanSerials", this.tempOutStockDatas)
      // this.successMsg("添加成功!")
    },
    _close() {
      this.showDialog = false
    },
    _closePro() {
      this.showDialogPro = false
    }
  }
}
</script>